import React from 'react';
import './Expertise.css';

const Expertise = () => {
  const expertiseItems = [
    'Android App Developers',
    'iPhone App Developers',
    'Cross-platform App Developers',
    'Blockchain Developers',
    'Java Developers',
    '.Net Developers',
    'WordPress Developers',
    'Game App Developers',
    'Web Developers',
    'eCommerce Developers',
    'UI/UX Designer',
    'Python Developers',
  ];

  return (
    <div className="expertise-section">
      <h2>Our Dedicated Developer Expertise</h2>
      <div className="expertise-grid">
        {expertiseItems.map((item, index) => (
          <div key={index} className="expertise-item">
            <a href="#">{item}</a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Expertise;