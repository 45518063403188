import React from 'react';
import { Carousel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CarousalComponent.css';
import crm from "../Assest/crmss.jpeg";
import hrms from "../Assest/hrms.jpeg";
import attendence from "../Assest/amss.jpeg";
import sms from "../Assest/smsssss.jpeg";
import dating from "../Assest/dating1.jpg";
import loan from "../Assest/loan1.jpg"

const CarouselComponent = () => {
  const navigate = useNavigate();

  const handleContactClick = () => {
    navigate('/contact-us');
  };

  const slides = [
    {
      title: "Customer Relationship Management System (CRM)",
      description: "Optimizes interactions and data management to enhance customer satisfaction and loyalty.",
      imgSrc: crm
    },
    {
      title: "School Information Management System (SIMS)",
      description: "Efficiently manages student and teacher data, attendance, grades, and more.",
      imgSrc: sms
    },
    {
      title: "Human Resource Management System (HRMS)",
      description: "Streamlines HR tasks like recruitment, payroll, and employee management.",
      imgSrc: hrms
    },
    {
      title: "Attendance Management System (AMS)",
      description: "Streamlines attendance tracking and reporting for improved efficiency and accuracy.",
      imgSrc: attendence
    },
    {
    title: "TrueBond Android Application",
    description:  "Find meaningful connections,Swipe, chat, and meet someone special.Your perfect match is just a tap away!",
    imgSrc: dating
  },
  {
    title: "Easy Loan Android Application",
    description:  "Get the funds you need, when you need them.Quick, easy, and secure loan applications.Empowering your financial journey, one loan at a time!",
    imgSrc: loan
  },

  ];

  return (
    <div className="container2">
      <Carousel interval={2000} controls={true} indicators={true}>
        {slides.map((slide, index) => (
          <Carousel.Item key={index}>
            <div className="slide-content">
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap" }}>
                <div style={{ zIndex: 0, flexGrow: 1, flexBasis: "400px", padding: "10px" }}>
                  <div className="slid-text">
                    <h3 className="slide-title">{slide.title}</h3>
                    <p className="slide-description">{slide.description}</p>
                    <div className="button-group">
                      <button className="slider-btn" onClick={handleContactClick}>
                        Get in touch
                      </button>
                    </div>
                  </div>
                </div>
                <div style={{ zIndex: 0, flexGrow: 1, flexBasis: "400px", padding: "10px" }}>
                  <img className="d-block slide-image" src={slide.imgSrc} alt={slide.title} />
                </div>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default CarouselComponent;
