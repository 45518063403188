import './App.css';
import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Home from './Component/Home';
import JobApplicationForm from './Component/Carrer/Carrer';
import ContactUs from './Component/ContactUs/ContactUs';
import Services from './Component/Services/Services';
import BannerOutsource from './Component/OutSourcing/BannerOutsorce';
import Industries from './Component/Industries/Industries';
import PrivacyPolicy from './Component/PrivacyPolicy/PrivacyPolicy';
import TermsConditions from './Component/Term&Condition/Term&Condition';
import OurProduct from './Component/OurProduct/OurProduct';
import GlobalLearning from "./Component/GlobalLearning/GlobalLearning";
import AndroidDevelop from './pages/AndroidDeveloper/AndroidDevelop';
import IphoneDevelop from './pages/IphoneDevelopment/IphoneDevelop';
import CrossPlatfrom from './pages/CrossPlatform/CrossPlatfrom';
import ReactDevelopment from './pages/ReactDevelopment/ReactDevelopment';
import AOS from 'aos';
import "aos/dist/aos.css"
import FlutterDevelopment from './pages/FlutterDevelopment/FlutterDevelopment';

const metadata = {
  '/': { title: 'Asv Consulting Services | Expert Business Solutions for Sustainable Growth', description:  'Asv Consulting Services: Expert business consulting solutions tailored to help your company grow and succeed. Specializing in strategic planning, process optimization, and innovative problem-solving, we empower businesses to thrive in today’s competitive market. Partner with us for personalized, results-driven consulting that transforms your challenges into opportunities.', keywords: 'home, services, company' },
  '/career': { title: 'ACS - Career', description: 'Join our team', keywords: 'career, jobs' },
  '/about': { title: 'ACS - About Us | Expert Business Solutions and Strategic Consulting', description: 'Discover Asv Consulting Services, a trusted partner in delivering innovative business solutions. We specialize in management consulting, strategy development, and digital transformation to help organizations achieve sustainable growth and success. Learn more about our expertise, values, and commitment to excellence.', keywords: 'about, company' },
  '/contact-us': { title: 'Asv Consulting Services – Expert Business Solutions and Support', description: 'Get in touch with Asv Consulting Services for expert business solutions tailored to your needs. Contact our team today to discuss how we can help drive your business forward with strategic advice and customized consulting services.', keywords: 'contact, support' },
  '/services': { title: 'ACS - Services', description: 'Explore our services', keywords: 'services, offerings' },
  '/outsourcing': { title: 'ACS - Outsourcing', description: 'Outsourcing services offered', keywords: 'outsourcing' },
  '/industries': { title: 'ACS - Industries', description: 'Industries we serve', keywords: 'industries' },
  '/privacy-policy': { title: 'ACS - Privacy Policy', description: 'Our privacy policy', keywords: 'privacy' },
  '/terms-conditions': { title: 'ACS - Terms and Conditions', description: 'Terms of service', keywords: 'terms' },
  '/our-product': { title: 'ACS - Our Product', description: 'Our product offerings', keywords: 'product' },
};

function MetadataUpdater() {
  const location = useLocation();
  useEffect(() => {
    AOS.init({
      once:true
    });
  }, [])
  useEffect(() => {
    const updatePageMetadata = () => {
      const path = location.pathname;
      const pageMetadata = metadata[path] || metadata['/']; // Default to home if path not found

      // Update document title
      document.title = `${pageMetadata.title}`;

      // Update meta tags
      updateMetaTag('description', pageMetadata.description);
      updateMetaTag('keywords', pageMetadata.keywords);
    };

    const updateMetaTag = (name, content) => {
      let metaTag = document.querySelector(`meta[name="${name}"]`);
      if (metaTag) {
        metaTag.setAttribute("content", content);
      } else {
        metaTag = document.createElement("meta");
        metaTag.setAttribute("name", name);
        metaTag.setAttribute("content", content);
        document.head.appendChild(metaTag);
      }
    };

    updatePageMetadata();
  }, [location.pathname]);

  return null;
}

function App() {
  return (
    <Router>
      {/* MetadataUpdater should be inside Router to access useLocation */}
      <MetadataUpdater />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/career' element={<JobApplicationForm />} />
        <Route path='/about' element={<GlobalLearning />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/services' element={<Services />} />
        <Route path='/outsourcing' element={<BannerOutsource />} />
        <Route path='/industries' element={<Industries />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-conditions' element={<TermsConditions />} />
        <Route path='/our-product' element={<OurProduct />} />

        {/* Services pages */}
        <Route path='/android-development' element={<AndroidDevelop />} />
        <Route path='/iphone-development' element={<IphoneDevelop />} />
        <Route path='/cross-platfrom' element={<CrossPlatfrom />} />
        <Route path='/react-development' element={<ReactDevelopment />} />
        <Route path='/fluter-development' element={<FlutterDevelopment />} />
      </Routes>
    </Router>
  );
}

export default App;
