import React from 'react';
import abuts from "../../Component/Assest/abots.jpg";  // Correcting the path if needed
import GlobalLearning2 from '../GlobalLearning2/GlobalLearning2';
import Footer from '../Footer/Footer';
import Navbar1 from '../Navbar/Navbar1';
import Navbar2 from '../Navbar/Navbar2';
import './GlobalLearning.css';  // Import the CSS file

function LearningTransformation() {


  return (
    <>
      <Navbar1 />
      <Navbar2 />
      <h1 className="about-title">About Us</h1>
      <div className="learning-container">
        <div className="text-section">
          <h4>How It Started</h4>
          <h1>Our Dream is Global Learning Transformation</h1>
          <p>At ASV Consulting Services, we pride ourselves on delivering exceptional consulting solutions tailored to meet the unique needs of our clients. Established with a vision to empower businesses, we specialize in strategic planning, operational excellence, and innovative problem-solving.

Our team comprises industry experts with extensive experience across various sectors. We are committed to understanding your challenges and providing actionable insights that drive growth and efficiency.</p>
        </div>
        <div className="image-section">
          <img src={abuts} alt="Learning in Action" className="responsive-image" />
         
        </div>
      </div>
      <GlobalLearning2 />
      <Footer />
    </>
  );
}

export default LearningTransformation;
