import React from 'react';
import './Solutions.css'; // Assuming you named the CSS file

const services = [
  {
    icon: '📱',
    title: 'Digital Transformation',
    description: 'Digital transformation is a strategic initiative that incorporates digital technology across all areas of an organization.',
  },
  {
    icon: '🌐',
    title: 'Web Development',
    description: 'A website with seamless flow, visual delights, and enhanced and immersive user experience.',
  },
  {
    icon: '🛒',
    title: 'Data Management',
    description: 'Data management is the process of collecting, organizing, and using data in a secure, efficient, and cost-effective manner',
  },
  {
    icon: '🔗',
    title: 'Web Portal',
    description: 'Bridge the gap with your customers between your business and customers, vendors, and partners.',
  },
  {
    icon: '📊',
    title: 'Digital Marketing',
    description: 'Comprehensive and all-inclusive digital marketing services that transform your ideas into a reality.',
  },
  {
    icon: '📝',
    title: 'Dedicated Resources',
    description: 'Every project, big or small, is important; hence, ACS dedicates one resource per client.',
  }
];

const Solution = () => {
  return (
    <section className="app-development-section">
      
      <div className="services-container">
        {services.map((service, index) => (
          <div className="service-card" key={index}>
            <div className="service-icon">{service.icon}</div>
            {/* Title without link */}
            <h3 className="service-title">{service.title}</h3>
            <p className="service-description">{service.description}</p>
            {/* Read More text without link */}
            {/* <span className="read-more">Read More</span> */}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Solution;