import React from 'react'
import "./iphonedevelop.css"
import Navbar1 from '../../Component/Navbar/Navbar1'
import Navbar2 from '../../Component/Navbar/Navbar2'

import iPhone1 from "../../Component/Assest/pages/iphon-icon/iphone-icon-1.png"
import iPhone2 from "../../Component/Assest/pages/iphon-icon/iphon-icon-2.png"
import iPhone3 from "../../Component/Assest/pages/iphon-icon/iphon-icon-3.png"
import iPhone4 from "../../Component/Assest/pages/iphon-icon/iphon-icon-8.png"
import iPhone5 from "../../Component/Assest/pages/iphon-icon/iphon-icon-9.png"
import iPhone6 from "../../Component/Assest/pages/iphon-icon/iphon-icon-4.png"
import iPhone7 from "../../Component/Assest/pages/iphon-icon/iphon-icon-5.png"
import iPhone8 from "../../Component/Assest/pages/iphon-icon/iphon-icon-6.png"
import iPhone9 from "../../Component/Assest/pages/iphon-icon/iphon-icon-10.png"
import iPhone10 from "../../Component/Assest/pages/iphon-icon/iphon-icon-7.png"
import Footer from '../../Component/Footer/Footer'

const IphoneDevelop = () => {
  return (
    <>
    <Navbar1/>
    <Navbar2/>
    <div className='iphone_develop'>
      <h1>iPhone Development</h1>
    </div>

    <div className='iphone_category'>
        <div className='top_content'>
          <h1>Explore the line-up.</h1>
          <span>Compare all models</span>
        </div>
        <div className='iphone_category_flex'>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="400">
            <div className='iphone_category_image'>
              <img src={iPhone1} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>Built for Apple Intelligence</p>
            </div>
          </div>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="500">
          <div className='iphone_category_image'>
              <img src={iPhone1} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>Built for Apple Intelligence</p>
            </div>
          </div>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="600">
          <div className='iphone_category_image'>
              <span>—</span>
            </div>
          </div>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="700">
          <div className='iphone_category_image'>
              <span>—</span>
            </div>
          </div>

          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="400">
            <div className='iphone_category_image'>
              <img src={iPhone2} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>A18 Pro chip with 6-core GPU</p>
            </div>
          </div>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="500">
          <div className='iphone_category_image'>
              <img src={iPhone3} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>A18 chip with 5-core GPU</p>
            </div>
          </div>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="600">
          <div className='iphone_category_image'>
          <img src={iPhone4} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>A16 Bionic chip with 5-core GPU</p>
            </div>
          </div>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="700">
          <div className='iphone_category_image'>
          <img src={iPhone5} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>A15 Bionic chip with 5-core GPU</p>
            </div>
          </div>

          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="400">
            <div className='iphone_category_image'>
              <img src={iPhone6} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>Camera Control</p>
            </div>
          </div>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="500">
          <div className='iphone_category_image'>
              <img src={iPhone6} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>Camera Control</p>
            </div>
          </div>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="600">
          <div className='iphone_category_image'>
              <span>—</span>
            </div>
          </div>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="700">
          <div className='iphone_category_image'>
              <span>—</span>
            </div>
          </div>

          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="400">
            <div className='iphone_category_image'>
              <img src={iPhone7} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>Pro camera system</p>
              <p>Our most advanced 48MP Fusion</p>
              <p>5x Telephoto camera</p>
              <p>48MP Ultra Wide camera</p>
            </div>
          </div>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="500">
          <div className='iphone_category_image'>
              <img src={iPhone8} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>Advanced dual-camera system</p>
              <p>48MP Fusion camera</p>
              <p>2x Telephoto</p>
              <p>12MP Ultra Wide camera</p>
            </div>
          </div>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="600">
          <div className='iphone_category_image'>
              <img src={iPhone9} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>Dual-camera system</p>
              <p>48MP Main camera</p>
              <p>2x Telephoto</p>
              <p>12MP Ultra Wide camera</p>
            </div>
          </div>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="700">
          <div className='iphone_category_image'>
              <img src={iPhone9} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>Dual-camera system</p>
              <p>12MP Main camera</p>
              <p>—</p>
              <p>12MP Ultra Wide camera</p>
            </div>
          </div>

          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="400">
            <div className='iphone_category_image'>
              <img src={iPhone10} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>Up to 27 hours video playback</p>
            </div>
          </div>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="500">
            <div className='iphone_category_image'>
              <img src={iPhone10} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>Up to 26 hours video playback</p>
            </div>
          </div>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="600">
            <div className='iphone_category_image'>
              <img src={iPhone10} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>Up to 26 hours video playback</p>
            </div>
          </div>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="700">
            <div className='iphone_category_image'>
              <img src={iPhone10} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>Up to 15 hours video playback</p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className='iphon_video_section'>
        <div className='container'>
          <h1>Get to know iPhone.</h1>
          <div className='app_flex_images'>
              <div className='app_image'>
             
              </div>
              <div className='app_image'></div>
              <div className='app_image'></div>
          </div>
        </div>
      </div> */}
    <Footer/>
    </>
  )
}

export default IphoneDevelop