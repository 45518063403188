import React from 'react';
import './GlobalLearning2.css';

const servicesData = [
  { id: 1, name: 'Banking', icon: '💰' },
  { id: 2, name: 'Retail', icon: '🏷️' },
  { id: 3, name: 'Healthcare', icon: '💊' },
  { id: 4, name: 'Education', icon: '📚' },
  { id: 5, name: 'E-commerce', icon: '🛒' },
  { id: 6, name: 'Dating', icon: '💑' },
  { id: 7, name: 'Classified', icon: '👷' },
  { id: 8, name: 'News & Magazine', icon: '📰' },
  { id: 9, name: 'Job Portal', icon: '💼' },
  { id: 10, name: 'Maps Navigation', icon: '📍' },
  { id: 11, name: 'Food', icon: '🍽️' },
  { id: 12, name: 'Social Network', icon: '💬' },
  { id: 13, name: 'Real Estate', icon: '🏠' },
  { id: 14, name: 'Event App', icon: '🎫' },
  { id: 15, name: 'OTT Platform', icon: '📺' },
 
];

const GlobalLearning2 = () => {
  return (
    <div className="global-service-container">
      <h2>Industry We Serve</h2>
      <div className="global-service-grid">
        {servicesData.map((service) => (
          <div className="global-service-card" key={service.id}>
            <div className="icon">{service.icon}</div>
            <h3>{service.name}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GlobalLearning2;
