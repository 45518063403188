import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; // import your CSS styles
import OfficesSection from './OfficeSection';
import FooterLast from './FooterLast';

const Footer = () => {

  const handleWhatsAppClick = () => {
    window.open('https://api.whatsapp.com/send?phone=9315652636', '_blank');
  };

  return (
    <>
      <footer className="footer">
        <div className="footer-column">
          <h3>About Company</h3>
          <ul>
            <li><Link className="footer-link" to="/about-us">About Us</Link></li>
            <li><Link className="footer-link" to="/career">Career</Link></li>
           
            <li><Link className="footer-link" to="/outsourcing">Outsourcing</Link></li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Mobile Services</h3>
          <ul>
            <li><span>Android App Development</span></li>
            <li><span>Android UX-UI App Development</span></li>
            <li><span>Android Game Development</span></li>
            <li><span>iPhone App Development</span></li>
            <li><span>iPhone UX-UI App Development</span></li>
            <li><span>iPhone Game Development</span></li>
            <li><span>iPad App Development</span></li>
            <li><span>iPad UX/UI App Development</span></li>
            <li><span>React Native App Development</span></li>
            <li><span>Kotlin App Development</span></li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Web Services</h3>
          <ul>
            <li><span>Web Apps Development</span></li>
            <li><span>PHP Development</span></li>
            <li><span>JAVA Development</span></li>
            <li><span>Angular JS Development</span></li>
            <li><span>Node JS Development</span></li>
            <li><span>React JS Development</span></li>
            <li><span>Ruby On Rails Development</span></li>
            <li><span>Python Development</span></li>
            <li><span>Django Development</span></li>
            <li><span>Framework Development</span></li>
            <li><span>Laravel Development</span></li>
            <li><span>Zend Development</span></li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Data Management</h3>
          <ul>
            <li><span>Data Migration</span></li>
            <li><span>Data Mining</span></li>
            <li><span>Data Visualization</span></li>
            <li><span>Data Analytics & Reporting</span></li>
          </ul>
          <h3>Digital Marketing</h3>
          <ul>
            <li><span>SEO</span></li>
            <li><span>SMO</span></li>
            <li><span>Email Marketing</span></li>
            <li><span>Brand Management</span></li>
            <li><span>Meta Ads</span></li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Resources</h3>
          <ul>
            <li>
              <Link className="footer-link" to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link className="footer-link" to="/terms-conditions">Terms & Conditions</Link>
            </li>
            <li>
              <Link className="footer-link" to="/contact-us">Contact Us</Link>
            </li>
          </ul>
          <h3>Connect With Us</h3>
          <div className="footer-acssocial-icons">
            <span onClick={handleWhatsAppClick} className="social-icon">
              <i className="fab fa-whatsapp"></i>
            </span>
            <a href="https://www.facebook.com/profile.php?id=61563162418689&mibextid=ZbWKwL" className="social-icon" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://www.instagram.com/asvconsultingservices?igsh=MTQ0NjVoNGtpbGIwMg==" className="social-icon" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.linkedin.com/company/asv-consulting-services/" className="social-icon" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-linkedin-in"></i>
            </a>
          </div>
        </div>
      </footer>
      <OfficesSection />
      <FooterLast />
    </>
  );
};

export default Footer;
