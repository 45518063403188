import React from 'react';
import './MasterClassSection.css'; // Import custom CSS
import brandd from '../Assest/img1.jpeg'
import ba from '../Assest/img2.jpeg'
import bas from '../Assest/img3.jpeg'
import basd from '../Assest/about3.jpeg'
import basdf from '../Assest/img5.jpeg'

const MasterClassSection = () => {
  return (
    <div className="masterclass-section">
      <div className="text-section">
        <h5 className="commitments-title">ACS COMMITMENTS</h5>
        <h2 className="main-title">Empower Your Business With Access to World-Class Products, Services & Solutions</h2>
        <p className="description">
          We are geared to building, developing, and deploying feature-rich apps, 
          saving our clients a lot of time, effort, and money that goes into web 
          development exercises. Our mobile app development experts are well-versed 
          in using the latest and advanced technologies to deliver high-performance 
          apps for the best user experience.
        </p>
        {/* <button className="view-more-btn">View More</button> */}
      </div>

      <div className="image-section">
        <div className="gear-shape">
          <div className="image-collage">
            <img src={brandd} alt="Collage 1" className="collage-image" />
            <img src={ba} alt="Collage 2" className="collage-image" />
            <img src={bas} alt="Collage 3" className="collage-image" />
            <img src={basd} alt="Collage 4" className="collage-image" />
          </div>
          <div className="center-image">
            <img src={basdf} alt="Center" className="center-image-content" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MasterClassSection;