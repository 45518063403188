import React from 'react'
import "./flutterdevelopment.css"
import Navbar1 from '../../Component/Navbar/Navbar1'
import Navbar2 from '../../Component/Navbar/Navbar2'
import FluterAbout from "../../Component/Assest/pages/fluter_img/flutter_about.png"
import Footer from '../../Component/Footer/Footer'

const FlutterDevelopment = () => {
  return (
    <>
      <Navbar1 />
      <Navbar2 />
      <div className='fluter_development'>
        <h1>Flutter Development</h1>
      </div>
      <div className='fluter_content_section'>
          <div className='text'>
            <h2>Flutter for web app development: benefits, threats, applications</h2>
            <p className='paragraph'>Rishabh’s extensive range of React development services will help you build high-performance web & mobile solutions.</p>
          </div>
        <div className='fluter_flex'>
        <div className='content'>
          <p>In 2022, Flutter, the software development kit created by Google, officially outperformed its fiercest competitor, React Native, becoming the new leader in cross-platform technology. According to Google reports, more than 100,000 Flutter-based applications have been released to the public. Despite its growing popularity,</p>
          <p>Flutter is still most well-known as a multiplatform (Android & iOS) mobile development tool. Meanwhile, only a few industry experts know that Flutter can also be used in web applications! Find out the possibilities, applications, and risks associated with Flutter for web development and discover what value it can add to your business.</p>
        </div>
          <div className='image'>
            <img src={FluterAbout} alt='Fluter-About'/>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default FlutterDevelopment
