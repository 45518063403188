import React from 'react';
import './OurProduct.css'; // Import the CSS file
import crmm from "../../Component/Assest/crm.jpeg";
import smsss from "../../Component/Assest/smsss.jpg";
import hrmss from "../../Component/Assest/hrms.jpeg";
import attendencs from "../../Component/Assest/attendencs.jpeg";
import dating from "../Assest/dating1.jpg";
import loan from "../Assest/loan1.jpg";
import Navbar1 from '../Navbar/Navbar1';
import Navbar2 from '../Navbar/Navbar2';
import Footer from '../Footer/Footer';

const products = [
  { 
    id: 1, 
    name: 'Customer Relationship Management (CRM)',      
    image: crmm, 
    description: [
      'Enhances Customer Satisfaction and Loyalty',
      'Streamlines Sales and Marketing',
      'Improves Collaboration and Communication'
    ]
  },
  { 
    id: 2, 
    name: 'Student Information Management System (SIMS)',      
    image: smsss,
    description: [
      'Centralized Student Data Management',
      'Improves Communication and Engagement',
      'Supports Academic Planning and Performance Tracking'
    ]
  },
  { 
    id: 3, 
    name: 'Human Resource Management System (HRMS)',      
    image: hrmss,
    description: [
      'Automates HR Processes',
      'Centralized Employee Data',
      'Improves Employee Self-Service'
    ]
  },
  { 
    id: 4, 
    name: 'Attendance Management System (AMS)',      
    image: attendencs,
    description: [
      'Automates Attendance Tracking',
      'Ensures Compliance and Accuracy',
      'Enhances Productivity and Accountability'
    ]
  },
  { 
    id: 4, 
    name: 'TrueBond Application',      
    image: dating,
    description: [
      'Find meaningful connections.',
'Swipe, chat, and meet someone special.',
'Your perfect match is just a tap away!',
    ]
  },
  { 
    id: 4, 
    name: 'Easy Loan Application',      
    image: loan,
    description: [
   '   Get the funds you need, when you need them.',
    '  Quick, easy, and secure loan applications.',
   '   Empowering your financial journey, one loan at a time!',
    ]
  },
  // Add more products here
];

const OurProduct = () => {
  return (
    <>
      <Navbar1 />
      <Navbar2 />
      <div className="product-page">
        <div className="banner">
          <h1>Our Products</h1>
          <p>Browse through our exclusive collection</p>
        </div>
        <div className="product-list">
          {products.map((product) => (
            <div key={product.id} className="product-row">
              <img src={product.image} alt={product.name} className="product-image" />
              <div className="product-details">
                <h2>{product.name}</h2>
                <ul className="product-description">
                  {product.description.map((point, index) => (
                    <li key={index}>{point}</li>
                  ))}
                </ul>
                <a href='/contact-us'>
                  <button>Contact Us</button>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OurProduct;
