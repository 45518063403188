import React from 'react'
import Navbar1 from './Navbar/Navbar1'
import Navbar2 from './Navbar/Navbar2'
import Footer from './Footer/Footer'
import CarouselComponent from './Carosel/Carousal'
import AppDevelopmentSection from './AppDevelopmentSection/AppDevelopmentSection'
import MasterClassSection from './MasterClassSection/MasterClassSection'

import StatisticsBanner from './Statics/StaticsBanner'
import Solutions from './Solutions/Solutions'

import FaqSection from './FAQ/Faq'
import ProcessSection from './ProcessSection/ProcessSection'
import OurClient from './OurClient/OurClient'
import Chatbots from './Chatbot/Chatbots'



function Home() {
  return (
   <>
   <Navbar1/>
   <Navbar2/>
   <CarouselComponent/>
   <div style={{margin:'0px'}}>
   <AppDevelopmentSection/>

   <Solutions/>
   </div>
   <MasterClassSection/>

   <ProcessSection/>
   <StatisticsBanner/>

   <FaqSection/>
   <OurClient/>
   {/* <Chatbots/> */}
   <Footer/>

   </>
  )
}

export default Home